import React, { Component } from 'react';
import { Row, Col ,Form} from 'reactstrap';

import PropTypes from 'prop-types';
import '../css/semanticdropdown.css'
import '../css/transition.css'


export class AttendanceReport extends Component {
    static displayName = AttendanceReport.name;
    
    constructor(props) {
        super(props);
    }

    
    render() {

        console.log(this.props);
        return (

            <div className="form-container">
                <Row>
                    <Col xs="12" sm={{ size: 12 }} lg={{ size: 12 }}><h1 className="form-title" >Check-In Sheet </h1></Col>
                </Row>
                <Form>
                    <div>
                        <Row>
                            <Col xs={{ size: 6 }} sm={{ size: 4, order: 1 }} lg={{ size: 3, order: 1 }}>
                                <img src={`http://www.akridge.com/images/employees/${this.props.attendanceReport.employee.photo}`} style={{width:"150px", height:"auto"}} />
                            </Col>
                            <Col className="report-text-name vertical-center" xs={{ size: 6 }} sm={{ size: 4, order: 2 }} lg={{ size: 3, order: 2 }}>
                                {`${this.props.attendanceReport.employee.firstName.charAt(0).toUpperCase() + this.props.attendanceReport.employee.firstName.slice(1)} ${this.props.attendanceReport.employee.lastName.charAt(0).toUpperCase() + this.props.attendanceReport.employee.lastName.slice(1)}`    }
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ size: 6 }} sm={{ size: 4, order: 1 }} lg={{ size: 3, order:1 }} className="sub-title">
                                Email:
                            </Col> 
                            <Col className="report-text" xs={{ size: 6 }} sm={{ size: 4, order: 2 }} lg={{ size: 3, order: 2 }}>
                                {this.props.attendanceReport.employee.email}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="sub-title" xs={{ size: 6 }} sm={{ size: 4, order: 1 }} lg={{ size: 3, order: 1 }}>
                                Date:
                            </Col>
                            <Col className="report-text" xs={{ size: 6 }} sm={{ size: 4, order: 2 }} lg={{ size: 3, order: 2 }}>
                                {this.props.attendanceReport.date}
                            </Col>
                        </Row>
                         <Row>
                            <Col className="sub-title" xs={{ size: 6}} sm={{ size: 4, order: 1 }} lg={{ size: 3, order: 1 }}>
                            Time:
                            </Col>
                            <Col className="report-text"  xs={{ size: 6 }} sm={{ size: 4, order: 2 }} lg={{ size: 3, order: 2 }}>
                                {this.props.attendanceReport.time}
                        </Col>
                        </Row>
                        <Row style={{ display: this.props.attendanceReport.isPresent ? "flex" : "none" }} >
                            <Col className="sub-title" xs={{ size: 6 }} sm={{ size: 4, order: 1 }} lg={{ size: 3, order: 1 }}>
                                Working from:
                            </Col>
                            <Col className="report-text"  xs={{ size: 6 }} sm={{ size: 4, order: 2 }} lg={{ size: 3, order: 2 }}>
                                {this.props.attendanceReport.workLocation}
                            </Col>
                        </Row>
                        <Row style={{ display: !this.props.attendanceReport.isPresent ? "flex" : "none" }}>
                            <Col className="sub-title" xs={{ size: 6 }} sm={{ size: 4, order: 1 }} lg={{ size: 3, order: 1 }}>
                                Day Off :
                            </Col>
                            <Col className="report-text"  xs={{ size: 6 }} sm={{ size: 4, order: 2 }} lg={{ size: 3, order: 2 }}>
                                {this.props.attendanceReport.ptoType}
                            </Col>
                        </Row>
                        {
                            (this.props.attendanceReport.qrCodeImage) ? <Row>
                                <Col className="sub-title" xs={{ size: 6 }} sm={{ size: 4, order: 1 }} lg={{ size: 3, order: 1 }}>
                                    QRCode:
                            </Col>
                                <Col className="report-text" xs={{ size: 6 }} sm={{ size: 4, order: 2 }} lg={{ size: 3, order: 2 }}>
                                    <img src={this.props.attendanceReport.qrCodeImage} style={{ width: "200px", height: "auto" }} />
                                </Col>
                            </Row>:""
                        }
                        
                    </div>
                </Form>

            </div>



        );
    }

}

AttendanceReport.defaultProps = {
    attendanceReport: {}
}
AttendanceReport.propTypes = {
    attendanceReport: PropTypes.object.isRequired
}
