import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col,  Input,Form,  FormGroup, Label } from 'reactstrap';

export class QuestionaryCovidV2 extends Component {
    static displayName = QuestionaryCovidV2.name;

    constructor(props) {
        super(props);
       
        this.handleAnswer = this.handleAnswer.bind(this);
    }

    handleAnswer(questionId, event) {
       this.props.answerQuestion(questionId,event.target.value)
    }  
   
  
    render() {
      
        const compare = function (a, b) {
            if (a.questionId < b.questionId) {
                return -1;
            }
            if (a.questionId > b.questionId) {
                return 1;
            }
            return 0;
        };
        const compareOrder = function (a, b) {
            if (a.order < b.order) {
                return -1;
            }
            if (a.order > b.order) {
                return 1;
            }
            return 0;
        };
        const groupOneRender = [];
        const groupOne = (this.props.questions.filter(function (question) { return question.group === 1 })).sort(compare);
        groupOne.sort(compareOrder);
        for (let i = 0; i < groupOne.length; i++) {

            const answers = groupOne[i].answer.split(',');

            if (groupOne[i].questionType === "multichoice") {
                groupOneRender.push(
                    <Row  key={"q" + groupOne[i].questionId}  >
                        <Col sm={{ size: 8 }}>{groupOne[i].text}:</Col>
                        {

                            groupOne[i].options.map((option, index) => {                               
                                return <Col key={"o" + option.id} sm={{ size: 2 }}>
                                    <FormGroup check><Label check><Input type="radio" value={option.value} checked={answers[0] === option.value} onChange={this.handleAnswer.bind(null,groupOne[i].questionId)} />{' ' + option.text}</Label></FormGroup></Col>

                            })
                        }
                    </Row>
                );
            } else {
                groupOneRender.push(
                    <Row className="row-border" key={"q" + groupOne[i].questionId} >
                        <Col >{groupOne[i].text}
                            <Input type="number" value={groupOne[i].answer} onChange={this.handleAnswer.bind(null,groupOne[i].questionId)} id={"textquestion" + groupOne[i].questionId} placeholder="100" />
                        </Col>
                    </Row>
                );

            }
        }

        const groupTwoRender = [];
        const groupTwo = (this.props.questions.filter(function (question) { return question.group === 2 })).sort(compare);
        groupTwo.sort(compareOrder);
   
        for (let i = 0; i < groupTwo.length; i++) {
            const answers = groupTwo[i].answer.split(',');
            groupTwoRender.push(
                <Row className="legend" key={"q" + groupTwo[i].questionId}>
                    <Col xs={{ size: 12 }} sm={{ size: 8 }}>{groupTwo[i].text}</Col>
                {
                    groupTwo[i].options.sort(compare).map((option, index) => {
                        return <Col xs={{ size: 6 }} sm={{ size: 1 }} key={"o" + option.id}>
                            <FormGroup check>
                                <Label check> <Input type="radio" value={option.value} checked={answers[0] === option.value} onChange={this.handleAnswer.bind(null, groupTwo[i].questionId)} />{' ' + option.text} </Label>
                            </FormGroup >
                        </Col>
                    })
                }
                </Row>
            )
        }
        const groupThreeRender = [];
        const groupThree = (this.props.questions.filter(function (question) { return question.group === 3 })).sort(compare);
        groupThree.sort(compareOrder);
        for (let i = 0; i < groupThree.length; i++) {
            const answers = groupThree[i].answer.split(',');
            groupThreeRender.push(
                <Row className="legend" key={"q" + groupThree[i].questionId}>
                    <Col xs={{ size: 12 }} sm={{ size: 8 }}>{groupThree[i].text}</Col>
                    {
                        groupThree[i].options.sort(compare).map((option, index) => {
                            return <Col xs={{ size: 6 }} sm={{ size: 1 }} key={"o" + option.id}>
                                <FormGroup check>
                                    <Label check> <Input type="radio" value={option.value} checked={answers[0] === option.value} onChange={this.handleAnswer.bind(null, groupThree[i].questionId)} />{' ' + option.text} </Label>
                                </FormGroup >
                            </Col>
                        })
                    }
                </Row>
            )
        }
        


        return (
            <div className="form-container">
                <Row>
                    <Col xs="12" sm={{ size: 12 }} lg={{ size: 12 }}><h1 className="form-title" >Coronavirus Disease (COVID-19)<br />Bio Self-Screening Certification</h1></Col>
                </Row>
                <Form>
                    <div>
                      
                        <Row >
                            <Col sm="12" lg="12">
                                {groupOneRender}
                            </Col>
                        </Row>

                        <Row>
                            <Col className="sub-title"> If you are not fully vaccinated, in the past 14 days, have you:</Col>

                        </Row>

                        <Row >
                            <Col sm="12" lg="12">
                                {groupTwoRender}
                            </Col>
                        </Row>
                        <Row >
                            <Col sm="12" lg="12">
                                <div className="temperature-screening">If you are not fully vaccinated, in the past 10 days, have you:</div>
                                {groupThreeRender}
                            </Col>
                        </Row>
                        
                        <Row >
                            <Col>
                                <p className="submit-covid">
                                  By hitting submit, I certify that the above information is accurate and true to the best of my knowledge.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Form>
                
            </div>

                
        );
    }

}
QuestionaryCovidV2.defaultProps = {
    questions: []   
}
QuestionaryCovidV2.propTypes = {
    questions: PropTypes.array.isRequired,
    answerQuestion: PropTypes.func.isRequired

}