import React, { Component } from 'react';
import { Button, FormGroup, Spinner,Form,Col,Modal, ModalBody,ModalHeader,ModalFooter } from 'reactstrap';
import { QuestionaryCovidV2 } from './QuestionaryCovidV2';
import { SearchEmployee } from './SearchEmployee';
import { AttendanceChoices } from './AttendanceChoices';
import { AttendanceReport } from './AttendanceReport';


export class Attendance extends Component {
    static displayName = Attendance.name;

    constructor(props) {
        super(props);
        this.state = {
            
            loading: true,
            questions: [],
            employees: [],
            typePtos: [],
            typeLocations: [],
            isPresent: true,
            selectedLocation: 0,
            selectedTypePto: 0,
            employeeId: 0,
            step: 1,
            attendanceResponse: {},
            showDontCome:false

        };
        this.fillQuestion = this.fillQuestion.bind(this);
        this.onSelectEmployee = this.onSelectEmployee.bind(this);
        this.onChangeIsPresent = this.onChangeIsPresent.bind(this);
        this.onChangeTypePTO = this.onChangeTypePTO.bind(this);
        this.onChangeWorkLocation = this.onChangeWorkLocation.bind(this);
        this.changeStep = this.changeStep.bind(this);
        this.submitAttendance = this.submitAttendance.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    fillQuestion(questionId, value) {
     
        const questions = this.state.questions;
       questions.find(question => question.questionId === questionId).answer = value;
        this.setState({ questions: questions });

    }
    onSelectEmployee(employeeId) {
        console.log(employeeId)
        this.setState({ employeeId:employeeId });
    }
    onChangeWorkLocation(locationId) {
        this.setState({ selectedLocation:locationId});
    }
    onChangeTypePTO(ptoId) {
        this.setState({ selectedTypePto:ptoId});
    }
    onChangeIsPresent(isPresent) {
        if (this.state.isPresent !== isPresent) {
            if (isPresent) this.setState({ isPresent: isPresent, selectedTypePto: 0, selectedLocation: this.state.typeLocations[0].key });
            else this.setState({ isPresent: isPresent, selectedLocation: 0, selectedTypePto: this.state.typePtos[1].key });
        }
        
    }
    changeStep(step, event) {
        this.setState({ step: step });
    }
    twoDigits(d) {
        if (0 <= d && d < 10) return "0" + d.toString();
        if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
        return d.toString();
    }
    submitAttendance() {
    
        const answers = [];
        this.state.questions.forEach((question, index) => {
            console.log(question)
            answers.push({
                questionId: question.questionId,
                questionAnswer: question.answer
            });
        });

        const d = new Date();       
        const sqlDate = d.getFullYear() + "-" +
            this.twoDigits(d.getMonth()+1) + "-" +
            this.twoDigits(d.getDate()) + "T" +
            this.twoDigits(d.getHours()) + ":" +
            this.twoDigits(d.getMinutes()) + ":" +
            this.twoDigits(d.getSeconds());
        
        const dailyAttendance = {
                        employeeId:this.state.employeeId,
                        isPresent:this.state.isPresent,
                        typeLocationId:this.state.isPresent?this.state.selectedLocation:null,
                        typePtoId: !this.state.isPresent? this.state.selectedTypePto:null,
                        Date: sqlDate,
                        dailyAttendanceQuestionary: {
                            questionaryId: 3,
                            answers: answers
                        }
        }

        
        
        this.setState({ loading: true });
        this.sendAttendace(dailyAttendance);
    
    }

    closeModal() {
        this.setState({ showDontCome: !this.state.showDontCome });
    }

    componentDidMount() {
        this.getAttendanceInfo();
    }
    render() {
         
        let contents = this.state.loading
            ? <div className="form-container">
                    <Spinner type="grow" color="primary" />
                    <Spinner type="grow" color="secondary" />
                    <Spinner type="grow" color="success" />
                    <Spinner type="grow" color="danger" />
                    <Spinner type="grow" color="warning" />
                    <Spinner type="grow" color="info" />
                    <Spinner type="grow" color="light" />
                    <Spinner type="grow" color="dark" />
                </div>            
            : <div>
                {
                    this.state.step === 1 ? <div>
                        <SearchEmployee
                            employees={this.state.employees}
                            onSelectEmployee={this.onSelectEmployee}
                            employeeId={this.state.employeeId}

                        />
                        <div style={{
                            opacity: this.state.employeeId <= 0 ? 0.7 : 1,
                            pointerEvents: this.state.employeeId <= 0 ? "none" : "initial"
                        }} >
                            <AttendanceChoices
                                workLocations={this.state.typeLocations}
                                PTOTypes={this.state.typePtos}
                                isPresent={this.state.isPresent}

                                PTOTypeSelected={this.state.selectedTypePto}
                                WorkLocationTypeSelected={this.state.selectedLocation}

                                onChangeWorkLocation={this.onChangeWorkLocation}
                                onChangeTypePTO={this.onChangeTypePTO}
                                onChangeIsPresent={this.onChangeIsPresent}
                            />
                        </div>
                    </div> :""
                }
                   
                {
                    this.state.step===2?<QuestionaryCovidV2
                    questions={this.state.questions}
                    answerQuestion={this.fillQuestion}
                    />:""
                }

                {
                    this.state.step === 3 ?
                        <AttendanceReport
                            attendanceReport={this.state.attendanceResponse}
                    /> : ""
                }
                <div className="form-container" style={{
                    opacity: this.state.employeeId <= 0 ? 0.7 : 1,
                    pointerEvents: this.state.employeeId <= 0 ? "none" : "initial"
                }}>
                    <Form>
                        <FormGroup row>
                            
                             <Col > 
                                    <Button style={{ display: this.state.step === 1 ? "inline-block" : "none" }}   color="danger" onClick={this.submitAttendance}>Submit</Button>{' '}                                    
                             </Col>
                            
                            
                        </FormGroup>
                    </Form>
                </div>
                  
            </div>;


        return (
            <div>
                {contents}
                <div>
                    
                    <Modal isOpen={this.state.showDontCome} toggle={this.closeModal} >
                        <ModalHeader toggle={this.closeModal}>Alert</ModalHeader>
                        <ModalBody>
                            Because you answered "yes" to one or more of the Bio Self-Certification questions or "no" to the Temperature Screening question, you may not come into the office or an Akridge managed property.
                            Please notify HR immediately and consider contacting your primary care physician.


                        </ModalBody>
                        <ModalFooter>
                          
                            <Button color="danger" onClick={this.closeModal}>Closed</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
 
        );
    }

    
    async sendAttendace(attendance) {

        // POST request using fetch with async/await
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(attendance)
        };
        const ajaxAttendaceResponse = await fetch('api/dailyattendance', requestOptions);
        const dataAttendaceResponse = await ajaxAttendaceResponse.json();

        
        if (dataAttendaceResponse.isSuccess)
            this.setState({ step: 3, attendanceResponse: dataAttendaceResponse, loading: false });
        else

            this.setState({ step: 1, loading: false, showDontCome:true});
        
    }

    async getAttendanceInfo() {
        const ajaxEmployee = await fetch('api/Employee');
        const employeesData = await ajaxEmployee.json();
     
        const ajaxEmptyQuestionary = await fetch('api/Questionary/emptyquestionary/3');
        const emptyQuestionary = await ajaxEmptyQuestionary.json();

        const ajaxTypeLocations = await fetch('api/typelocation');
        const typeLocationsData = await ajaxTypeLocations.json();

        const ajaxTypePtos = await fetch('api/typepto');
        const typePtosData = await ajaxTypePtos.json();
        
        this.setState({
            employees: employeesData,
            questions: emptyQuestionary,
            typePtos: typePtosData.reduce((typePtoData, typePto) => ([...typePtoData,
            {
                key: typePto.id,
                text: typePto.type,
                value: typePto.id

            }]), []),
            typeLocations: typeLocationsData.reduce((typeLocationData, typeLocation) => ([...typeLocationData,
            {
                key: typeLocation.id,
                text: typeLocation.type,
                value: typeLocation.id

            }]), []),
            selectedLocation: typeLocationsData[0].id,
            selectedTypePto: typePtosData[1].id,
            loading: false
        });
    }
}