import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import '../css/semanticdropdown.css'
import '../css/transition.css'




export class SearchEmployee extends Component {
    static displayName = SearchEmployee.name;

    constructor(props) {
        super(props);
        this.onSelectEmployee = this.onSelectEmployee.bind(this);
    }
    onSelectEmployee(event, dropdown) {
        this.props.onSelectEmployee(dropdown.value);
    }
    
    render() {
        

        let dropDonwItems = this.props.employees.reduce((aItems, employee) => ([...aItems, 
            {
                key: employee.id,
                text: employee.firstName + " " + employee.lastName,
                value: employee.id,
                image: { avatar: true, src: "http://www.akridge.com/images/employees/"+employee.photo}
            }]), []);
       
        return (            
                <div className="form-container">                 
                <Row>
                    <Col xs="12" style={{fontSize:"18px",fontWeight:"bold", paddingBottom:"15px"}}>Employee:</Col>
                        <Col xs="12">
                            <Dropdown
                                placeholder='Select Employee'
                                fluid
                                selection
                                search
                                options={dropDonwItems}
                                value={this.props.employeeId > 0 ? this.props.employeeId:0}
                                onChange={this.onSelectEmployee}
                            />
                        </Col>
                    </Row>
                </div>            
        );
    }
}
SearchEmployee.defaultProps = {
    employees: []
}
SearchEmployee.propTypes = {
    employees: PropTypes.array.isRequired,
    onSelectEmployee: PropTypes.func.isRequired,
    employeeId: PropTypes.number.isRequired
}
