import React, { Component } from 'react';
import { Container ,Row, Col} from 'reactstrap';
import Logo from '../images/akridge_logo_color_rgb.svg';

export class Layout extends Component {
  static displayName = Layout.name;
     //<NavMenu />
  render () {
    return (
      <div>       
         <Container>
                <Row style={{ marginTop: "20px"}}>
                <Col xs="12" sm={{ size: 4, order: 2 }} lg={{ size: 4, push: 8 }}  ><div className="logo-container"><img src={Logo} className="form-logo" alt="logo" ></img></div></Col>
                    <Col xs="12" sm={{ size: 8 }} lg={{ size: 8, order: 1 }}><h1 className="form-title" >DAILY CHECK-IN</h1></Col>
                </Row>
                <hr style={{ borderTop: "1px solid #000000" }} />
          {this.props.children}
        </Container>
      </div>
    );
  }
}
