import React, { Component } from 'react';
import { Row, Col,  FormGroup, Label, Input } from 'reactstrap';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import '../css/semanticdropdown.css'
import '../css/transition.css'

export class AttendanceChoices extends Component {
    static displayName = AttendanceChoices.name;

    constructor(props) {
        super(props);

        this.onChangeIsPresent = this.onChangeIsPresent.bind(this);
        this.onChangeTypePTO = this.onChangeTypePTO.bind(this);
        this.onChangeWorkLocation = this.onChangeWorkLocation.bind(this);
    }

    onChangeIsPresent(optionValue, event)
    {
        this.props.onChangeIsPresent(optionValue);
    }
    onChangeTypePTO(event, object) {
        this.props.onChangeTypePTO(object.value);
    }
    onChangeWorkLocation(event, object) {
        this.props.onChangeWorkLocation(object.value);
    }
    render() {

        
        let optionalContent = this.props.isPresent
            ? <Row>
                <Col xs="12" style={{ fontSize: "18px", fontWeight: "bold", paddingBottom: "15px" }}>Work location:</Col>
                <Col xs="12">
                    <Dropdown
                        placeholder='In Office'
                        fluid
                        selection
                        value={this.props.WorkLocationTypeSelected}
                        options={this.props.workLocations}
                        onChange={this.onChangeWorkLocation}
                    />
                </Col>
            </Row>
            : <Row>
                <Col xs="12" style={{ fontSize: "18px", fontWeight: "bold", paddingBottom: "15px" }}>Type of day off:</Col>
                <Col xs="12">
                    <Dropdown
                        placeholder='PTO'
                        fluid
                        selection
                        value={this.props.PTOTypeSelected}
                        options={this.props.PTOTypes}
                        onChange={this.onChangeTypePTO}
                    />
                </Col>
            </Row>;
        return (

            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    <Row className="legend" >
                        <Col xs={{ size: 12 }} sm={{ size: 8 }} style={{ fontSize: "18px", fontWeight: "bold", paddingBottom: "15px" }}>Are you working today ?</Col>
                        <Col xs={{ size: 6 }} sm={{ size: 2 }} >
                            <FormGroup check>
                            <Label check> <Input type="radio"  checked={this.props.isPresent === true} onChange={this.onChangeIsPresent.bind(null,true)} />{' '}Yes </Label>
                            </FormGroup >
                        </Col>
                        <Col xs={{ size: 6 }} sm={{ size: 2 }} >
                            <FormGroup check>
                            <Label check> <Input type="radio" checked={this.props.isPresent === false} onChange={this.onChangeIsPresent.bind(null, false)} />{' '}No </Label>
                            </FormGroup >
                        </Col>
                    </Row>
                    {optionalContent}
                </div>


               
        );
    }

}

AttendanceChoices.defaultProps = {
    workLocations: [],
    typePTO: [],
    isPresent: true,
    PTOTypeSelected: 0,
    WorkLocationTypeSelected:0
}
AttendanceChoices.propTypes = {
    workLocations: PropTypes.array.isRequired,
    PTOTypes: PropTypes.array.isRequired,
    isPresent: PropTypes.bool.isRequired,
    onChangeWorkLocation: PropTypes.func.isRequired,
    onChangeTypePTO: PropTypes.func.isRequired,
    onChangeIsPresent:PropTypes.func.isRequired,
    PTOTypeSelected: PropTypes.number.isRequired,
    WorkLocationTypeSelected: PropTypes.number.isRequired
}
