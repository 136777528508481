import React, { Component } from 'react';
import { AttendanceReport } from './AttendanceReport';
import { Spinner, Modal, ModalBody, ModalHeader, ModalFooter,Button } from 'reactstrap';
import queryString from 'query-string';
import access from '../sounds/access.wav'
import error from '../sounds/error.wav'

export class Result extends Component {
    static displayName = Result.name;

    constructor(props) {
        super(props);
        this.state = {

            loading: true,
            attendanceResultResponse: {},
            showExpired: false,
           
        };
        this.closeModal = this.closeModal.bind(this);
        this.onOpenend = this.onOpenend.bind(this);
    }
    closeModal() {

      
        this.setState({ showExpired: !this.state.showExpired});
    }
    onOpenend() {
       
    }
    componentDidMount() {
        this.getAttendanceInfo();
    }
    render() {
      
        let contents = this.state.loading
            ? <div className="form-container">
                <Spinner type="grow" color="primary" />
                <Spinner type="grow" color="secondary" />
                <Spinner type="grow" color="success" />
                <Spinner type="grow" color="danger" />
                <Spinner type="grow" color="warning" />
                <Spinner type="grow" color="info" />
                <Spinner type="grow" color="light" />
                <Spinner type="grow" color="dark" />
            </div>
            : <div>
                <audio autoPlay>
                    <source src={this.state.attendanceResultResponse.isExpired ? error : access} />
                </audio>
               
                <AttendanceReport
                    attendanceReport={this.state.attendanceResultResponse}
                  />              
              </div>;


        return (
            <div>
                {contents}
                <div>

                    <Modal onEnter={this.onOpenend} isOpen={this.state.showExpired} toggle={this.closeModal} >
                        <ModalHeader toggle={this.closeModal}>Expired QRCode</ModalHeader>
                        <ModalBody>
                            This is an expired QRCode.
                            This person is not be admited to the property.
                        </ModalBody>
                        <ModalFooter>

                            <Button color="danger" onClick={this.closeModal}>Closed</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>

        );
    }


    async getAttendanceInfo() {
        let url = this.props.location.search;
        let params = queryString.parse(url);

        const ajaxGetAttendanceResult = await fetch(`api/dailyattendance/getreportbyid/${params.attendanceid}`);
        const attendanceResultData = await ajaxGetAttendanceResult.json();
       

        this.setState({
            attendanceResultResponse: attendanceResultData,
            showExpired: attendanceResultData.isExpired,
            loading: false
        });
       
      
    }
}