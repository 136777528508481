import React, { Component } from 'react';
import { Input, Label, FormGroup, Form, Table, Spinner} from 'reactstrap';

import PropTypes from 'prop-types';


export class ListDailyAttendance extends Component {
    static displayName = ListDailyAttendance.name;
    
    constructor(props) {
        super(props);        
        this.state = {

            loading: true,
            defaultDate: this.getTodayForDataPicker(),
            inOut: 0,
            searchText:"",
            dailyAttendanceEmployee: [],
            dailyAttendanceEmployeeFilter: [],
            baseUrl: 'http://www.akridge.com/images/employees/'
           
        };
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeInOut = this.onChangeInOut.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.orderListBy = this.orderListBy.bind(this);
    }
    getTodayForDataPicker() {
        //format date 2020-05-27
        let d = new Date();
        return `${d.getFullYear()}-${this.twoDigits(d.getMonth() + 1)}-${this.twoDigits(d.getDate())}`;
    }
    twoDigits(d) {
        if (0 <= d && d < 10) return "0" + d.toString();
        if (-10 < d && d < 0) return "-0" + (-1 * d).toString();
        return d.toString();
    }
    onChangeDate(event) {     
        //format date 2020-05-27        
        this.setState({ defaultDate: event.target.value });
        this.getListAttendance(event.target.value);
    }
    onChangeText(event) {
        let filterEmployeeList = [];
        filterEmployeeList = this.filterbyStatusType(parseInt(this.state.inOut), this.state.dailyAttendanceEmployee);
        filterEmployeeList = this.filterByName(event.target.value, filterEmployeeList);
        this.setState({
            dailyAttendanceEmployeeFilter: filterEmployeeList,
            searchText: event.target.value
        });
    }
    onChangeInOut(event) {
        let filterEmployeeList = [];
        
        filterEmployeeList = this.filterbyStatusType(parseInt(event.target.value), this.state.dailyAttendanceEmployee);
        filterEmployeeList = this.filterByName(this.state.searchText,filterEmployeeList);
        
        this.setState({
            dailyAttendanceEmployeeFilter: filterEmployeeList,
            inOut: event.target.value
        });
    }
    filterByName(searchText, listEmployee) {
        if (searchText === "") return listEmployee;
        return listEmployee.reduce((listByName, employee) => {
            var name = (employee.firstName + " " + employee.lastName).toLowerCase();
            if (name.search(searchText.toLowerCase()) >= 0) listByName.push(employee);
            return listByName;
        }, []);
    }
    filterbyStatusType(type,listEmployee) {
        switch (type) {
            case 0:
                return listEmployee;                
            case 1:
                return listEmployee.reduce(
                    (checkInEmployees, employee) => {
                        if (employee.attendance.attendanceId) {
                            if (employee.attendance.isPresent) checkInEmployees.push(employee);
                        }
                        return checkInEmployees;
                    }, []);               
            case 2:
                return listEmployee.reduce(
                    (ptoEmployees, employee) => {
                        if (employee.attendance.attendanceId) {
                            if (!employee.attendance.isPresent) ptoEmployees.push(employee);
                        }
                        return ptoEmployees;
                    }, []);
                
            case 3:
                return listEmployee.reduce(
                    (notCheckInEmployees, employee) => {
                        if (!(employee.attendance.attendanceId)) {
                            notCheckInEmployees.push(employee);
                        }
                        return notCheckInEmployees;
                    }, []);              
        }
    }

    orderListBy(field, event) {
        
        event.preventDefault();

        switch (field) {
            case "time":
                this.state.dailyAttendanceEmployeeFilter.sort(
                    (employee1, employee2) => {
                        console.log(employee1.attendance.time);
                        console.log(employee2.attendance.time);
                        if (!(employee1.attendance.time) && !(employee2.attendance.time)) return 0;
                        if (!(employee1.attendance.time)) return 1;
                        if (!(employee2.attendance.time)) return -11;
                        return (employee1.attendance.time > employee2.attendance.time) ? 1 : -1;
                    }
                );
                
                break;
            case "pto":
                this.state.dailyAttendanceEmployeeFilter.sort(
                    (employee1, employee2) => {
                        if (!(employee1.attendance.pto) && !(employee2.attendance.pto)) return 0;
                        if (!(employee1.attendance.pto)) return 1;
                        if (!(employee2.attendance.pto)) return -1;
                        return (employee1.attendance.pto > employee2.attendance.pto)?1:-1;
                    }
                );
                
                break;
            case "location":
                
                this.state.dailyAttendanceEmployeeFilter.sort(
                    (employee1, employee2) => {
                        if (!(employee1.attendance.location) && !(employee2.attendance.location)) return 0;
                        if (!(employee1.attendance.location)) return 1;
                        if (!(employee2.attendance.location)) return -1;
                        return (employee1.attendance.location > employee2.attendance.location) ? 1 : -1;
                    }
                );
                break;

        }
        console.log(this.state.dailyAttendanceEmployeeFilter);
        this.setState({ dailyAttendanceEmployeeFilter: this.state.dailyAttendanceEmployeeFilter });
    }
    formatAMPM(dateString) {
        var date = new Date(dateString);
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }
    renderListEmployees() {  
       
        return this.state.dailyAttendanceEmployeeFilter.map((employee, index) => {
           
            return <tr className="list-grid" key={"emp"+index}>
                <th scope="row"><img src={this.state.baseUrl + employee.photo} style={{ width: "40px", height: "auto" }} /></th>
                <td>{`${employee.firstName} ${employee.lastName}`}</td>
                <td>{employee.attendance.attendanceId ? (employee.attendance.isPresent ? "Checked In" : "PTO") : "Not Checked In"}</td>
                <td>{employee.attendance.attendanceId ? employee.attendance.time : ""}</td>
                <td>{employee.attendance.attendanceId ? (employee.attendance.isPresent ? employee.attendance.location : "") : ""}</td>
                <td>{employee.attendance.attendanceId ? (!employee.attendance.isPresent ? employee.attendance.pto : "") : ""}</td>
                <td><span className={employee.attendance.scannedQrcode ? "green-dot" : ""}></span></td>
                
            </tr>;
        });
    }
    componentDidMount() {
        this.getListAttendance(this.getTodayForDataPicker());
        console.log(this.getTodayForDataPicker());
        console.log("pass");
    }
    render() {
        const contentEmployee = this.renderListEmployees();
        const content = this.state.loading ? <div className="form-container">
            <Spinner type="grow" color="primary" />
            <Spinner type="grow" color="secondary" />
            <Spinner type="grow" color="success" />
            <Spinner type="grow" color="danger" />
            <Spinner type="grow" color="warning" />
            <Spinner type="grow" color="info" />
            <Spinner type="grow" color="light" />
            <Spinner type="grow" color="dark" />
            </div>
            : <div>
                <Form>
                    <FormGroup>
                        <Label className="label-custom" for="date">Date:</Label>
                        <Input
                            type="date"
                            name="date"
                            id="date"
                            placeholder="date"
                            value={this.state.defaultDate}
                            onChange={this.onChangeDate}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label className="label-custom" for="inout">Status:</Label>
                        <Input type="select" id="inout" value={this.state.inOut} onChange={this.onChangeInOut}>
                            <option value="0">All</option>
                            <option value="1">Checked In</option>
                            <option value="2">PTO</option>
                            <option value="3">Not Checked In</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label className="label-custom" for="inout">Name:</Label>
                        <Input type="text" onChange={this.onChangeText} value={this.state.searchText} placeholder="search by name" />
                    </FormGroup>

                </Form>
                <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                    <Table striped>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>Checked In</th>
                                <th><a href="#" onClick={this.orderListBy.bind(null,"time")}>  Time</a></th>
                                <th><a href="#" onClick={this.orderListBy.bind(null, "location")}>Location</a></th>
                                <th><a href="#" onClick={this.orderListBy.bind(null, "pto")}>Leave</a></th>
                                <th>Qrcode Scan</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {contentEmployee}
                        </tbody>
                    </Table>
                </div>
            </div>;
        
        return (
            <div>
                {content}
            </div>


        );
    }

    async getListAttendance(date) {
        console.log(`api/dailyattendance/getlistattendacebyday/${date}`);
        const ajaxAttendanceList = await fetch(`api/dailyattendance/getlistattendacebyday/${date}`);
        const attendanceListData = await ajaxAttendanceList.json();    
        console.log(attendanceListData);
        this.setState({
            dailyAttendanceEmployee: attendanceListData,        
            dailyAttendanceEmployeeFilter: attendanceListData,   
            loading: false
        });
    }
}
