import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import { Attendance } from './components/Attendance';
import { Result } from './components/Result';
import { ListDailyAttendance} from './components/ListDailyAttendance'
import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>        
        <Route  path='/result' component={Result} />
        <Route path='/listdayattendance' component={ListDailyAttendance} /> 
        <Route exact path='/index.html' component={Attendance} /> 
        <Route exact path='/' component={Attendance} />
        
      </Layout>
    );
  }
}
